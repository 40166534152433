import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";


export const RideList = () => {
    const [search, setSearch] = useState();
    const [number, setNumber] = useState();
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            await axios
                .get("https://sparkstoideas.daddy11.in/ride/getAllRides")
                .then((res) => {
                    setFilteredData(res.data.result);
                    setData(res.data.result);
                    setLoader(false)
                })
                .catch((err) => console.log(err));
        })();
    }, [loader]);

    useEffect(() => {
        const result = data.filter((e) => {
            return e._id.match(search)
        });
        setFilteredData(result);
    }, [search]);

    useEffect(() => {
        const result = data.filter((e) => {
            return e.vendorId.phno.match(number)
        });
        setFilteredData(result);
    }, [number]);

    const columns = [
        { name: "Index", selector: (e, i) => <p className="w-2">{i + 1}</p> },
        { name: "pickupLocation", selector: (e) => e.pickupLocation },
        { name: "dropLocation", selector: (e) => e.dropLocation },
        { name: "date", selector: (e) => e.date },
        { name: "time", selector: (e) => e.time },
        {
            name: "Action",
            cell: (e) => (
                <button
                    className="bg-purple-600 font-bold text-white rounded-md p-2"
                    onClick={() => navigate("/admin/vehicle_info", { state: e.carId })}
                >
                    Vehicle
                </button>
            ),
        },
        {
            name: "Action",
            cell: (e) =>
                <button
                    className="bg-orange-600 font-bold text-white rounded-md p-2"
                    onClick={() => navigate("/admin/details", { state: { Id: e.vendorId._id, type: "vendor" } })}
                >
                    Vendor
                </button>
        },
        {
            name: "Action",
            cell: (e) => (
                <button
                    onClick={() =>
                        navigate("/admin/ridedetails", { state: e })
                    }
                    className="bg-green-600 font-bold text-white rounded-md p-2"
                >
                    Details
                </button>
            ),
        },
    ];
    console.log(data);
    return (
        <>
            {loader && <Loader />}
            <div className="overflow-y-scroll">
                <DataTable
                    className="bg-black"
                    title="Ride List"
                    fixedHeader
                    subHeader
                    subHeaderComponent={
                        <div className="w-full flex justify-between">
                            <input
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                className="border px-4 py-2"
                                type="text"
                                placeholder="Search with Ride Id" />
                            <input
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                className="border px-4 py-2"
                                type="text"
                                placeholder="Search Phone number" />
                        </div>
                    }
                    highlightOnHover
                    fixedHeaderScrollHeight="440px"
                    columns={columns}
                    data={filteredData?.reverse()}
                    pagination />
            </div>
        </>
    );
};
