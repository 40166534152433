import React, { useEffect, useState } from 'react'
import img from '../asset/Screenshot_2023-10-25_163132-transformed_auto_x2.jpg'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from "../loader/Loader"

export const Forgot_password = () => {
    const { state } = useLocation()
    const [phone, setPhone] = useState()
    const [otp, setOtp] = useState()
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const [user, setUser] = useState(state)
    const [type, setType] = useState();
    const [otpsend, setOtpsend] = useState(false)

    useEffect(() => {
        if (user) {
            setType("auth_user")
        } else {
            setType("auth_vendor")
        }
    }, [user])

    const sendotp = (e) => {
        e.preventDefault()
        if (phone && phone.length == 10) {
            setLoader(true)
            const data = { phone, type }
            console.log(data)
            axios.post("https://sparkstoideas.daddy11.in/auth/otp", data)
                .then(res => {
                    console.log(res)
                    if (res.data.success == true) {
                        setLoader(false)
                        setOtpsend(true)
                        toast.success(res.data.message)
                    }
                    else {
                        setLoader(false)
                        toast.error(res.data.message)
                    }
                })

                .catch(err => {
                    setLoader(false)
                    console.log(err)
                })
        } else {
            toast.warning("Invalid number")
        }
    }

    const otpverify = (e) => {
        e.preventDefault()
        if (otp) {
            setLoader(false)
            const data = { Phone: phone, Otp: otp, Type: type }
            axios.post("https://sparkstoideas.daddy11.in/auth/verify", data)
                .then(res => {
                    console.log(res)
                    if (res.data.success == true) {
                        setOtpsend(true)
                        setLoader(false)
                        navigate("/login")
                        toast.success(res.data.message)
                    }
                    else {
                        setLoader(false)
                        toast.error(res.data.message)
                    }
                })
                .catch(err => {
                    setLoader(false)
                    console.log(err)
                })
        } else {
            toast.warning("Enter Otp")
        }
    }


    return (
        <>
            {loader && <Loader />}
            <div className='sm:p-8 w-full rounded-md'>
                <div style={{ backgroundImage: `url(${img})` }} className="bg-cover rounded-md bg-center w-full">
                    < div className='w-full rounded-md bg-[#00000036] grid place-items-center h-[500px]' >
                        <div className='w-full max-w-sm px-3'>
                            <div className='p-4 w-full rounded-md  bg-[#ffffff8c] space-y-6'>
                                <div className='flex rounded-md w-full bg-white hover:cursor-pointer'>
                                    <div onClick={() => setUser(true)} className={`w-full ${user && "bg-[#6EDA48] text-white"} text-center  font-medium p-2 rounded-md`}>User</div>
                                    <div onClick={() => setUser(false)} className={`w-full ${!user && "bg-[#6EDA48] text-white"} text-center  font-medium p-2 rounded-md`}>Vendor</div>
                                </div>

                                {!otpsend ?
                                    <form onSubmit={sendotp} autoComplete='off' className='space-y-7'>
                                        <div className='grid'>
                                            <label className='font-medium text-sm'>Mobile No.</label>
                                            <input value={phone} onChange={e => setPhone(e.target.value)} placeholder='Enter your number' className='p-3 text-sm rounded-md border-none focus:border-white' autoComplete='off' />
                                        </div>
                                        <input type='submit' className='w-full p-2 text-lg rounded-md font-bold bg-[#6EDA48]' value="Send OTP"></input>
                                    </form>
                                    :
                                    <form onSubmit={otpverify} autoComplete='off' className='space-y-7'>
                                        <div className='grid'>
                                            <input value={otp} onChange={(e) => setOtp(e.target.value)} type='text' placeholder='Enter your OTP' className='p-3 w-full text-sm rounded-md border-none focus:border-white' autoComplete='off' />
                                        </div>
                                        <input type='submit' className='w-full p-2 text-lg rounded-md font-bold bg-[#6EDA48]' value="Verify OTP"></input>
                                    </form>
                                }
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}
