import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PiArrowsLeftRightBold } from 'react-icons/pi';
import Loader from '../loader/Loader';
import { toast } from 'react-toastify';

export const Ridedetails = () => {
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState()
    const [otp, setOtp] = useState()
    const { state } = useLocation()
    // console.log(state)
    const navigate = useNavigate()
    useEffect(() => {
        (async () => {
            await axios.get(`https://sparkstoideas.daddy11.in/booking/get_venderbooking/${state._id}`)
                .then(res => {
                    // console.log(res.data.result)
                    setData(res.data.result)
                    setLoader(false)
                })
                .catch(err => console.log(err))
        })()
    })
    // console.log(data)

    const del = async () => {
        await axios.post("https://sparkstoideas.daddy11.in/auth/otpforRide", { id: state._id })
            .then(res => {
                if (res.data.success == true) {
                    setOtp(prompt(res.data.message))
                } else {
                    toast.error(res.data.message)
                }
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        if (otp) {
            (async () => {
                const data = { id: state._id, otp: otp }
                await axios.post("https://sparkstoideas.daddy11.in/ride/deleteRide", data)
                    .then(res => {
                        if (res.data.success == true) {
                            toast.success(res.data.message)
                            navigate("/admin/")
                        } else {
                            toast.error(res.data.message)
                        }
                    })
                    .catch(err => console.log(err))
            })()
        }
    }, [otp])

    return (
        <>
            {loader && <Loader />}
            <div className=' rounded-md'>
                <div className='p-8'>
                    <div className='w-full flex'>
                        <div className='w-1/3'>
                            <h1 className='text-lg font-bold bg-blue-50 px-4 py-2'>Ride Information</h1>
                            <div className='bg-white rounded-md my-3 p-2'>
                                <div className='text-center py-2.5'>
                                    <img className='w-20 h-20 mx-auto rounded-full' src={state.carId.vehicleImg} alt='' />
                                </div>
                                <div className='space-y-2.5'>
                                    <div className=''>
                                        <label className='text-gray-400 text-sm'>Ride Id</label>
                                        <div className='text-sm'>{state._id}</div>
                                    </div>
                                    <div className='flex'>
                                        <div className='w-full'>
                                            <label className='text-gray-400 text-sm'>pickupLocation</label>
                                            <div className='text-sm'>{state.pickupLocation}</div>
                                        </div>
                                        <div className='w-full'>
                                            <label className='text-gray-400 text-sm'>dropLocation</label>
                                            <div className='text-sm'>{state.dropLocation}</div>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                        <div className='w-full'>
                                            <label className='text-gray-400 text-sm'>date</label>
                                            <div className='text-sm'>{state.date}</div>
                                        </div>
                                        <div className='w-full'>
                                            <label className='text-gray-400 text-sm'>time</label>
                                            <div className='text-sm'>{state.time}</div>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                        <div className='w-full'>
                                            <label className='text-gray-400 text-sm'>price</label>
                                            <div className='text-sm'>{state.price}</div>
                                        </div>
                                        <div className='w-full'>
                                            <label className='text-gray-400 text-sm'>availableSeat</label>
                                            <div className='text-sm'>{state.availableSeat}</div>
                                        </div>

                                    </div>
                                    <div className=''>
                                        <button onClick={del} className='bg-red-500 text-white font-medium w-full p-1 rounded-md'>Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-2/3 pl-4'>
                            <h1 className='text-lg font-bold bg-blue-50 px-4 py-2'>Vendor Details</h1>
                            <div className='bg-white rounded-md my-3 p-2'>
                                <div className='space-y-3 py-2'>
                                    <div className='flex justify-between p-2 border-b'>
                                        <label className='text-gray-400 text-sm'>vehicle Number</label>
                                        <div className='text-sm'>{state.carId.vehicleNumber}</div>
                                    </div>
                                    <div className='flex justify-between p-2 border-b'>
                                        <label className='text-gray-400 text-sm'>vehicle Name</label>
                                        <div className='text-sm'>{state.carId.vehicleModel}-{state.carId.vehicleBrand}</div>
                                    </div>
                                    <div className='flex justify-between p-2 border-b'>
                                        <label className='text-gray-400 text-sm'>vendorId</label>
                                        <div className='text-sm'>{state.vendorId._id}</div>
                                    </div>
                                    <div className='flex justify-between p-2 border-b'>
                                        <label className='text-gray-400 text-sm'>vendor Name</label>
                                        <div className='text-sm'>{state.vendorId.firstName} {state.vendorId.lastName}</div>
                                    </div>
                                    <div className='flex justify-between p-2 border-b'>
                                        <label className='text-gray-400 text-sm'>Account Email</label>
                                        <div className='text-sm'>{state.vendorId.email}</div>
                                    </div>
                                    <div className='flex justify-between p-2 border-b'>
                                        <label className='text-gray-400 text-sm'>Phone Number</label>
                                        <div className='text-sm'>{state.vendorId.phno}</div>
                                    </div>
                                    <div className='flex justify-between p-2'>
                                        <label className='text-gray-400 text-sm'>Account LoginStatus</label>
                                        {state.vendorId.LoginStatus == 0 ? <div className='text-sm text-green-600'>Actived</div> : <div className='text-sm text-red-600'>Blocked</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-4 bg-white border border-gray-500 my-2'>
                        <div className='text-center text-xl font-medium border border-gray-600 p-2'>User Booking</div>
                        {data?.length == 0 ? <div className='bg-[#f3f3f1] p-6 text-center text-xl font-medium rounded-md'>No Inquiry Found</div> :
                            <>
                                <div className='my-2 underline underline-offset-2'>Total Inquiry {data?.length}</div>
                                <div className="relative overflow-x-auto">
                                    <table className="w-full text-sm text-left rtl:text-right bg-[#f3f3f1]">
                                        <thead className="text-xs text-gray-700 uppercase">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 border border-black">
                                                    Index
                                                </th>
                                                <th scope="col" className="px-6 py-3 border border-black">
                                                    profile
                                                </th>
                                                <th scope="col" className="px-6 py-3 border border-black">
                                                    firstName
                                                </th>
                                                <th scope="col" className="px-6 py-3 border border-black">
                                                    lastName
                                                </th>
                                                <th scope="col" className="px-6 py-3 border border-black">
                                                    email
                                                </th>
                                                <th scope="col" className="px-6 py-3 border border-black">
                                                    Phone number
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.map((e, i) => {
                                                const ee = e.uid
                                                return (
                                                    <tr key={i} className="bg-[#f2f2f1] border-b dark:border-gray-700">
                                                        <th
                                                            scope="row"
                                                            className="px-6 border border-black py-4 font-medium text-gray-900 whitespace-nowrap"
                                                        >
                                                            {i + 1}
                                                        </th>
                                                        <td className="px-6 py-4 border border-black"><img className='w-12 h-12 rounded-full' src={ee.profile == null ? require("../asset/user.jpg") : ee.profile} /></td>
                                                        <td className="px-6 py-4 border border-black">{ee.firstName}</td>
                                                        <td className="px-6 py-4 border border-black">{ee.lastName}</td>
                                                        <td className="px-6 py-4 border border-black">{ee.email}</td>
                                                        <td className="px-6 py-4 border border-black">{ee.phno}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </>}
                    </div>
                </div>
            </div>
        </>
    )
}
