import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "auth",
    initialState: { user: null, refresh: null },
    reducers: {
        user_data: (state, action) => {
            state.user = action.payload
        },
        refresh: (state, action) => {
            state.refresh = action.payload
            console.log(action.payload)
        }
    }
})

export const { user_data, refresh } = authSlice.actions
export const selectUser = state => state.auth.user
export const selectrefresh = state => state.auth.refresh